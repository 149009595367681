<script lang="ts">
  import Modal from "../common/Modal.svelte";
  import StudentRequestForm from "./StudentRequestForm.svelte";
  import { studentRequestStore as store } from "../stores";
  import { showToast } from "../toast/toast";
  import { studentRequestMessage } from "../../../utils/constants";
</script>

{#if $store.modalOpen}
  <Modal
    flyOut={$store.submitted}
    title="Request a Student License"
    onclose={() => ($store = { modalOpen: false, submitted: false })}
    background="var(--yellow)"
  >
    <StudentRequestForm
      oncancel={() => ($store = { modalOpen: false, submitted: false })}
      onsubmitted={() => {
        $store = { modalOpen: true, submitted: true };
        setTimeout(() => {
          $store = { modalOpen: false, submitted: true };
          showToast(studentRequestMessage);
        }, 500);
      }}
    />
  </Modal>
{/if}
